<template>
  <el-dialog
    :visible="show"
    :show-close="true"
    title="Password History"
    width="80%"
    @close="close"
    @open="fetchAccount"
  >
    <data-table
      v-if="account"
      :data="account.password_history_log"
      :loading="loading"
    >
      <el-table-column
        label="Time and Date"
        prop="timestamp"
        :formatter="formatTimestamp"
      />
      <el-table-column
        label="Action"
        prop="action"
        :formatter="formatAction"
      />
      <el-table-column
        label="Changed By"
        prop="user.name"
      />
      <el-table-column
        label="OS / Browser"
      >
        <template slot-scope="scope">
          {{ scope.row.os }} / {{ scope.row.browser }}
        </template>
      </el-table-column>
      <el-table-column
        label="IP"
        prop="ip"
      />
      <el-table-column
        label="Status"
        prop="status"
        class-name="capitalize"
      />
    </data-table>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="close">
        Close
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import RooofAccountAPI from '@/services/api/accounts'
import DataTable from '@/components/tables/DataTable'
import { dateToString } from '@/utils'

export default {
  name: 'PasswordHistoryDialog',
  components: {
    'data-table': DataTable
  },
  props: {
    show: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      account: undefined
    }
  },
  methods: {
    /**
     * Closes the dialog
     */
    close () {
      this.$emit('close')
    },
    /**
     * Fetch a rooof account from the api.
     */
    async fetchAccount () {
      try {
        this.loading = true
        const response = await RooofAccountAPI.users.retrieve(this.$route.params.id)
        this.account = response
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err, details)
      } finally {
        this.loading = false
      }
    },
    /**
     * Formatter for timestamp column
     *
     * @param {Object} row - row object
     */
    formatTimestamp (row) {
      return dateToString(row.timestamp, 'YYYY-MM-DD, h:mm A')
    },
    /**
     * Formatter for timestamp column
     *
     * @param {Object} row - row object
     */
    formatAction (row) {
      switch (row.action) {
        case 'password_change':
          return 'Change Password'
        case 'password_reset':
          return 'Reset Password'
        default:
          return ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .capitalize {
  text-transform: capitalize;
}
</style>
