<template>
  <div id="rooof-account-edit">
    <div class="controls">
      <el-button
        size="medium"
        @click="showChangePasswordDialog = true"
      >
        Change Password
      </el-button>
      <el-button
        size="medium"
        @click="showPasswordHistoryDialog = true"
      >
        Password History
      </el-button>
      <el-button
        size="medium"
        type="success"
        @click="refresh"
      >
        Refresh
      </el-button>
    </div>
    <rooof-account-form
      ref="form"
      class="form"
      :loading="loading"
      @submit="updateAccount"
      @deactivate="deactivateAccount"
    />
    <change-password-dialog
      :show="showChangePasswordDialog"
      @change-password="changePassword"
      @close="showChangePasswordDialog = false"
    />
    <password-history-dialog
      :show="showPasswordHistoryDialog"
      @close="showPasswordHistoryDialog = false"
    />
  </div>
</template>

<script>
import RooofAccountForm from './_components/RooofAccountForm'
import ChangePasswordDialog from './_components/ChangePasswordDialog'
import PasswordHistoryDialog from './_components/PasswordHistoryDialog'

import RooofAccountAPI from '@/services/api/accounts'

export default {
  name: 'RooofAccountEdit',
  components: {
    'rooof-account-form': RooofAccountForm,
    'change-password-dialog': ChangePasswordDialog,
    'password-history-dialog': PasswordHistoryDialog
  },
  data () {
    return {
      loading: false,
      showChangePasswordDialog: false,
      showPasswordHistoryDialog: false
    }
  },
  computed: {
    accountId () {
      return this.$route.params.id
    }
  },
  methods: {
    /**
     * Send update request to the API.
     *
     * @param {Object} formData
     */
    async updateAccount (formData) {
      this.loading = true
      try {
        await RooofAccountAPI.users.update(this.accountId, formData)
        await RooofAccountAPI.permissions.update(this.accountId, formData.object_permissions)

        this.$message({
          dangerouslyUseHTMLString: true,
          message: `Account <strong>${formData.name}</strong> updated`,
          type: 'success',
          duration: 3500
        })
        this.$router.push({ name: 'RooofAccounts' })
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err, details)
      }
      this.loading = false
    },
    /**
     * Send update request to the API to deactivate account.
     *
     * @param {Object} formData
     */
    async deactivateAccount (formData) {
      this.loading = true
      try {
        const permissions = {}
        permissions.is_active =
        permissions.is_poster =
        permissions.is_staff =
        permissions.is_superuser = false

        await RooofAccountAPI.users.partialUpdate(this.accountId, permissions)

        this.$message({
          dangerouslyUseHTMLString: true,
          message: `Account <strong>${formData.email || formData.name}</strong> deactivated`,
          type: 'success',
          duration: 3500
        })
        this.$router.push({ name: 'RooofAccounts' })
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err, details)
      }
      this.loading = false
    },
    /**
     * Send change password request to the API.
     *
     * @param {String} password
     */
    async changePassword (password) {
      try {
        await RooofAccountAPI.users.change_user_password(this.accountId, { password })
        this.refresh()
        this.$message({
          message: 'Password updated',
          type: 'success',
          duration: 3500
        })
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err.toString(), details)
      } finally {
        this.showChangePasswordDialog = false
      }
    },
    /**
     * Refresh the account form data in child component.
     */
    refresh () {
      this.$refs['form'].fetchAccount()
    }
  }
}
</script>

<style scoped>
.form {
  margin-top: 1em;
  width: 60%;
}
</style>
