<template>
  <div id="change-password-dialog">
    <el-dialog
      :visible="show"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="true"
      title="Change Password"
      width="450px"
      @close="close"
    >
      <change-password-form
        ref="form"
        @submit="$emit('change-password', $event)"
        @close="close"
      />
    </el-dialog>
  </div>
</template>

<script>
import ChangePasswordForm from './ChangePasswordForm'

export default {
  name: 'ChangePasswordDialog',
  components: {
    'change-password-form': ChangePasswordForm
  },
  props: {
    show: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    /**
     * Close the dialog.
     */
    close () {
      this.$refs['form'].reset()
      this.$emit('close')
    }
  }
}
</script>
