<template>
  <el-form
    ref="form"
    label-position="left"
    label-width="150px"
    :rules="rules"
    :model="form"
  >
    <el-form-item label="Password" prop="password1">
      <el-input v-model="form.password1" type="password" />
    </el-form-item>
    <el-form-item label="Confirm password" prop="password2">
      <el-input v-model="form.password2" type="password" />
    </el-form-item>
    <el-row type="flex" justify="end">
      <el-button @click="$emit('close')">
        Cancel
      </el-button>
      <el-button type="primary" @click="submit">
        Submit
      </el-button>
    </el-row>
  </el-form>
</template>

<script>
import { validators } from '@/utils/element'

export default {
  name: 'ChangePasswordForm',
  data () {
    return {
      form: {
        password1: '',
        password2: ''
      },
      rules: {
        password1: [
          { required: true, message: 'This field is required', trigger: 'blur' },
          { min: 8, message: 'Password must contain at least 8 characters', trigger: 'blur' },
          { validator: validators.password, trigger: 'blur' }
        ],
        password2: [
          { required: true, message: 'This field is required', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (value !== this.form.password1) {
                return callback(new Error('Passwords do not match'))
              }
              return callback()
            },
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    /**
     * Validate form data and emit submit event.
     */
    submit () {
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.$emit('submit', this.form.password1)
        }
      })
    },
    /**
     * Reset form and validation.
     */
    reset () {
      this.$refs['form'].resetFields()
      this.$refs['form'].clearValidate()
    }
  }
}
</script>
